export const adminPermission = {
  auth: {
    read: 'admin.auth.read',
    write: 'admin.auth.write'
  },
  userAccount: {
    read: 'admin.userAccount.read',
    write: 'admin.userAccount.write',
    resetPwd: 'admin.userAccount.resetPwd',
    setActive: 'admin.userAccount.setActive',
    changeRole: 'admin.userAccount.changeRole'
  },
  businessLicense: {
    read: 'admin.businessLicense.read',
    write: 'admin.businessLicense.write',
    delete: 'admin.businessLicense.delete'
  },
  sector: {
    read: 'admin.sector.read',
    write: 'admin.sector.write',
    delete: 'admin.sector.delete'
  },
  applicationService: {
    read: 'admin.applicationService.read',
    write: 'admin.applicationService.write',
    delete: 'admin.applicationService.delete'
  },
  applicationRequest: {
    read: 'admin.applicationRequest.read',
    checkedRequest: 'admin.applicationRequest.checkedRequest',
    approve: 'admin.applicationRequest.approve',
    changeDepartment: 'admin.applicationRequest.changeDepartment',
    changeSector: 'admin.applicationRequest.changeSector',
    reject: 'admin.applicationRequest.reject',
    markAsDeliver: 'admin.applicationRequest.markAsDeliver',
    suspendReopen: 'admin.applicationRequest.suspendReopen',
    readCityProvince: 'admin.applicationRequest.readCityProvince',
    readDistrict: 'admin.applicationRequest.readDistrict',
    readCommune: 'admin.applicationRequest.readCommune'
  },
  clientAccount: {
    read: 'admin.clientAccount.read',
    approval: 'admin.clientAccount.approval',
    resetPassword: 'admin.clientAccount.resetPassword'
  },
  documentForm: {
    read: 'admin.documentForm.read',
    write: 'admin.documentForm.write',
    delete: 'admin.documentForm.delete'
  },
  department: {
    read: 'admin.department.read',
    write: 'admin.department.write',
    delete: 'admin.department.delete'
  },
  departmentServiceLevel: {
    read: 'admin.departmentServiceLevel.read',
    write: 'admin.departmentServiceLevel.write',
    delete: 'admin.departmentServiceLevel.delete'
  },
  role: {
    read: 'admin.role.read',
    write: 'admin.role.write',
    delete: 'admin.role.delete'
  },
  reportData: {
    dashboard: 'admin.reportData.dashboard',
    serviceRequestCount: 'admin.reportData.serviceRequestCount',
    departmentRequestCount: 'admin.reportData.departmentRequestCount',
    paymentByLocation: 'admin.reportData.paymentByLocation',
    paymentBySector: 'admin.reportData.paymentBySector',
    businessLicense: 'admin.reportData.businessLicense',
    paymentTransferred: 'admin.reportData.paymentTransferred'
  },
  fileImport: {
    applicationService: 'admin.fileImport.applicationService'
  },
  landingPageCms: {
    read: 'admin.landingPageCms.read',
    write: 'admin.landingPageCms.write'
  }
};
